import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/home1'
import Login from '@/views/login/login'
import store from '@/vuex/store.js'

Vue.use(Router)
//动态路由

// 系统管理模块
const roleManagement = {
  path:'/system/roleManagement',
  name:'角色管理',
  component: () => import('@/views/system/roleManagement.vue')
}
const department = {
  path:'/system/department',
  name:'部门管理',
  component: () => import('@/views/system/department.vue')
}
const userManagement = {
  path:'/system/userManagement',
  name:'人员管理',
  component: () => import('@/views/system/userManagement.vue')
}
const programManagement = {
  path:'/system/programManagement',
  name:'栏目管理',
  component: () => import('@/views/system/programManagement.vue')
}
const systemConfiguration = {
  path:'/system/systemConfiguration',
  name:'系统配置',
  component: () => import('@/views/system/systemConfiguration.vue')
}
const shopManagement = {
  path:'/system/shopManagement',
  name:'店铺管理',
  component: () => import('@/views/system/shopManagement.vue')
}
const warehouseManagement = {
  path:'/system/warehouseManagement',
  name:'仓库管理',
  component: () => import('@/views/system/warehouseManagement.vue')
}
const companyManagement = {
  path:'/system/companyManagement',
  name:'企业管理',
  component: () => import('@/views/system/companyManagement.vue')
}
//商品管理
const goodsGroupList = {
  path:'/goodsManagement/goodsGroupList',
  name:'组合商品',
  component: () => import('@/views/goodsManagement/goodsGroupList.vue')
}
const goodsBrand = {
  path:'/goodsManagement/goodsBrand',
  name:'商品品牌',
  component: () => import('@/views/goodsManagement/goodsBrand.vue')
}
const goodsList = {
  path:'/goodsManagement/goodsList',
  name:'商品列表',
  component: () => import('@/views/goodsManagement/goodsList.vue')
}
//商品管理end
// 仓库管理
const repertoryList = {
  path:'/repertoryManagement/repertoryList',
  name:'入库记录',
  component: () => import('@/views/repertoryManagement/repertoryList.vue')
}
const outputRepertoryList = {
  path:'/repertoryManagement/outputRepertoryList',
  name:'出库记录',
  component: () => import('@/views/repertoryManagement/outputRepertoryList.vue')
}
// 订单管理
const pddOrder = {
  path:'/orderManagement/pddOrder',
  name:'拼多多订单',
  component: () => import('@/views/orderManagement/pddOrder.vue')
}
// 订单管理end
const undefind = {
  path: '*',
  name: '404',
  component: () => import("@/views/404/index"),
}

const ruleMapping = {
  roleManagement:roleManagement,
  department:department,
  userManagement:userManagement,
  programManagement:programManagement,
  systemConfiguration:systemConfiguration,
  companyManagement:companyManagement,
  shopManagement:shopManagement,
  warehouseManagement:warehouseManagement,
  pddOrder:pddOrder,
  goodsGroupList:goodsGroupList,
  goodsBrand:goodsBrand,
  goodsList:goodsList,
  repertoryList:repertoryList,
  outputRepertoryList:outputRepertoryList,
}
const routes = [
  {
    path: '/',
    component: Home,
    name: '首页',
    children:[]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]
const router =  new Router({
  mode:'hash',
  routes
})
//路由前置守卫
var getRouter='';
router.beforeEach((to,from,next) => {
  if(to.path === '/login'){
    next()
  }else{
    const token = sessionStorage.getItem('biToken')
    if(!token){
      console.log(to.path)
      next('/login')
    }else{
      next()
      // if (!getRouter) {//不加这个判断，路由会陷入死循环
      //     let params = {
      //       token:token
      //     }
      //     axios.post( "http://192.168.8.123:20000/api/menu/index",JSON.stringify(params),{headers:{"Content-Type": "application/json"}}).then(function(res){
      //       console.log(res)
      //       getRouter = res.data.data//后台拿到路由
      //       this.$store.commit('setRightList',res.data.data)
      //     })
      //     .catch(function(error){
      //       console.log(error)//失败
      //     })
      // } else {
      //   next()
      // }
    }
  }
})
export function initDynamicRoutes(){
  console.log(router)
  console.log('currentRoutes:',router.options.routes)
  //根据二级权限，对路由规则进行动态添加
  const currentRoutes = router.options.routes
  const rightList=store.state.rightList;
  currentRoutes[0].children = []
  rightList.forEach(item => {
    item.children.forEach(m => {
      const temp =  ruleMapping[m.sign]
      // console.log(temp)
      // temp.meta = m.right
      currentRoutes[0].children.push(temp)
    })
  })
  if(currentRoutes[currentRoutes.length-1].name!='404'){
    currentRoutes.push(undefind)
  }
  console.log(currentRoutes)
  // currentRoutes.forEach(item => {
  //   router.addRoute(item);
  // })
  router.addRoutes(currentRoutes);
  console.log('currentRoutes:',router.options.routes)
}
export default router