<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { loginUserPermissions } from '@/api/api.js'
import { initDynamicRoutes } from '@/router/index.js'
import { mapState } from 'vuex';
export default {
  name: 'App',
  computed: {
    ...mapState(['resquest']),
  },
  data() {
    return {
      
    }
  },
  created(){
    initDynamicRoutes()
  },
  watch:{
    $route(){
      console.log(sessionStorage.getItem('biToken'))

      loginUserPermissions().then(res => {
        if(res.data.code == 200 ){
          // console.log(res.data.data)
          this.$store.commit('setRightList',res.data.data)
          if(res.data.data.length){
            // sessionStorage.setItem('activePath',res.data.data[0].children[0].route);
          }else{
            this.$message({ duration: 2000,showClose: true,message: '您暂无功能权限，请联系管理员！',type: 'error' });
          }
        }
      })
    }
  }
}
</script>

<style>
/* @import "./common/font/font.css"; */
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.el-pagination {
  margin-top: 10px;
  position: static;
  text-align: center;
}
</style>
<style>
.iconfont {
  font-family: iconfont !important;
  font-size: 16px;
  font-style: normal;
}
html {
  height: 100%;
  line-height: 1.15;
}
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #657180;
  /* font-family:PingFangBold; */
  font-family:PingFangMedium;
}
*{
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
h4{
  font-size: 14px;
}
.isActive {
  color: #39f;
}
img{
  vertical-align: middle;
}    
ul,li {
  list-style-type: none;
}
P{
  margin:0;
  padding:0;
}
.pointer {
  cursor: pointer;
}
#app {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}
.formdv {
  margin-top: 10px;
  padding: 10px 0;
}
.font_weight{
  font-weight: 700;
}
.allSearch {
  padding:0;
}
.right {
  float: right;
}
.left {
  float: left;
}
.clearfix:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  /* height: 0; */
  clear:both;
  visibility: hidden;
}
.clearfix{
  zoom: 1;/*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex-grow-0 {
  min-width: 0;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-grow-1 {
  min-width: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-x-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.flex-y-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.flex-y-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}
.flex-x-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}
.flex-x-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
}
.fs-sm {
  font-size: 9pt;
}
.text-more {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.text-more-2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.text-more-4 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.content-wrapper::-webkit-scrollbar {
  width: 0; /*滚动条宽度*/
  height: 0; /*滚动条高度*/
  background-color: white;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #fff;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #fff;
  border: solid 1px #e1e2ea;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #e1e2ea;
}

.demo-table-expands {
  font-size: 0;
  width: 80%;
}
.demo-table-expands label {
  width: 90px;
  color: #99a9bf;
}
.accountList_table {
  width: 100%;
}
.accountList_table .pay-table-expands label {
  width: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.wh-100 {
  width: 100px;
}
.wh-300 {
  width: 300px !important;
}
.wh-400 {
  width: 400px !important;
}
.width-100v{
  width: 100%;
  max-width: 400px;
}
.view_nowrap {
  white-space: nowrap;
}


.table-info-value {
  color: #000;
}
.table-info-label {
  color: #999;
}

.drawer-title {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  flex-direction: row;
}
.drawer-title .drawer-close {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #030099;
  color: #fff;
}
</style>
<style>
