import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';

Vue.use(Vuex);
// 应用初始状态
const state = {
  userInfo: JSON.parse(sessionStorage.getItem('qiyue_user')) || '',
  token:sessionStorage.getItem('biToken') || '',
  rightList:JSON.parse(sessionStorage.getItem('rightList')) || [],
  
  resquest:'https://api.erp.essesuit.com',//测试地址
  // resquest:"https://api.erp.essesuit.com",//正式地址
  callStatus:true,
  callLoadingStatus:false,
  customerCall:false,
};
// 定义所需的 mutations
const mutations = {
  setRightList(state, data) {
    state.rightList = data
    sessionStorage.setItem('rightList',JSON.stringify(data))
  },
  //存储用户信息
  getuserInfo(state, pro) {
    state.userInfo = pro;
  },
  clearUserInfo(state, pro) {
    state.userInfo = {};
  },
  //存住token 信息
  setToken(state,data){
    state.token = data
  },
  clearToken(state,data){
    state.token = null
  },
  //存住callStatus 信息
  setcallStatus(state,data){
    state.callStatus = data
  },
  clearcallStatus(state,data){
    state.callStatus = true
  },
  //存住callLoadingStatus 信息
  setcallLoadingStatus(state,data){
    state.callLoadingStatus = data
  },
  clearcallLoadingStatus(state,data){
    state.callLoadingStatus = false
  },
  //存住customerCall 信息
  setcustomerCall(state,data){
    state.customerCall = data
  },
  clearcustomerCall(state,data){
    state.customerCall = false
  },
};

// 创建 store 实例
export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
});