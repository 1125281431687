<template>
  <div class="login">
    <!-- <div class="head">
      <div class="head_img">
        <img src="../../assets/img/logo.jpeg" alt="" />
      </div>
      <div class="title">系统</div>
    </div> -->
    <div class="content" style="">
      <!-- <img style="width:100%;height:100%;" src="../../assets/loginImages/login_bg.png" alt="" /> -->
      <div class="bgColor"></div>
      <div class="login_title" style="color:#fff;">
        <p style="font-size: 64px;line-height: 75px;">Hi，你好!</p>
        <p style="font-size: 58px;line-height: 68px;margin-top:32px;">欢迎进入ERP管理系统</p>
        <p style="font-size: 24px;line-height: 28px;margin-top:32px;">Welcome to the ERP Management System</p>
      </div>
      <div class="form_data">
        <!-- <h1>密码登录</h1> -->
        <p style="text-align:center">
          <!-- <img style="width:226px;" src="../../assets/loginImages/logo-login.png" /> -->
          <span style="font-size:32px;color:#16AFB9;">ERP管理系统</span>
        </p>
        <el-form class="form" :model="formData" :rules="formRule" ref="form">
          <el-form-item prop="username">
            <el-input
              v-model="formData.username"
              :placeholder="``"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" style="margin-bottom:6px;">
            <el-input
              type="password"
              v-model="formData.password"
              :placeholder="``"
              prefix-icon="el-icon-lock"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:10px;">
            <el-checkbox class="remember" v-model="checked" checked>记住密码</el-checkbox>
            <el-button @click="getresetPassword" type="text" class="forget"> 忘记密码？ </el-button>
          </el-form-item>
          <el-button type="primary" class="submit" @click="handleSubmit">登录</el-button>
          <!-- <p style="text-align:right;padding-top:10px;">
            <router-link style="color:#23848b" :to="{path:'/privacyPolicy'}" target="_blank" tag="a">隐私政策</router-link>
          </p> -->
        </el-form>
      </div>
      
      <div class="footer">
        <p>
          <!-- 版权所有 Copyright(C) 2013-2021 科技有限公司 粤ICP备2021128537号-1<br /> -->
        </p>
      </div>
    </div>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetdialog">
      <el-form :model="resetform" :rules="resetrules" ref="resetform">
        <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
          <el-input v-model="resetform.phone" autocomplete="off" size="mini" style="width:300px;"></el-input>
        </el-form-item>
        <el-form-item label="验证码" :label-width="formLabelWidth" prop="code">
          <el-input v-model="resetform.code" autocomplete="off" size="mini" style="width:300px;"></el-input>
          <el-button @click="getCode()" type="primary" :class="getCodeBtnDisable?'disabled-style':''" :disabled="getCodeBtnDisable" size="mini">{{codeBtnWord}}</el-button>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
          <el-input type="password" v-model="resetform.password" autocomplete="off" size="mini" prefix-icon="el-icon-lock" show-password style="width:300px;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="password_confirmation">
          <el-input type="password" v-model="resetform.password_confirmation" autocomplete="off" size="mini" prefix-icon="el-icon-lock" show-password style="width:300px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetresetForm('resetform')" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitresetForm('resetform')" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 重置密码end -->
  </div>
</template>

<script>
import { requestLogin,loginUserPermissions,resetPassword,sendCode } from '@/api/api.js'
import { getdata } from '@/api/apimock.js'
import { initDynamicRoutes } from '@/router/index.js'
export default {
  // 定义属性
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.resetform.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      formData: {
        username: "",
        password: "",
      },
      checked:true,
      formRule: {
        username: [{ required: true, message: `账号不能为空`, trigger: "blur" }],
        password: [{ required: true, message: `密码不能为空`, trigger: "blur" }],
      },
      resetdialog:false,//重置密码
      formLabelWidth: '120px',
      resetform:{
        phone:'',
        code:'',
        password:'',
        password_confirmation:'',
      },
      resetrules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        password_confirmation: [
          { required: true,validator: validatePass1, trigger: 'blur' }
        ],
      },//重置密码end
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      waitTime:61, // 获取验证码按钮失效时间
      getCodeBtnDisable:false,
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.$store.commit('setRightList',[])
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          // this.$router.push('/saleKanban/manageSalesConsultant')
          // sessionStorage.setItem('activePath','/saleKanban/manageSalesConsultant');
          let params = {
            username:this.formData.username,
            password:this.formData.password
          }
          requestLogin(params).then(res => {
            if(res.data.code == 200){
              this.$message({message: res.data.msg,type: 'success',duration:2000});
              this.$store.dispatch('setToken',res.data.data.token)
              sessionStorage.setItem('biToken',res.data.data.token)
              this.$store.dispatch('getuserInfo',res.data.data)
              sessionStorage.setItem('qiyue_user',JSON.stringify(res.data.data))
              loginUserPermissions().then(res => {
                if(res.data.code == 200 ){
                  console.log(res.data.data)
                  this.$store.commit('setRightList',res.data.data)
                  
                  if(res.data.data.length){
                    initDynamicRoutes()
                    console.log(res.data.data[0].children[0].route)
                    this.$router.push(res.data.data[0].children[0].route)
                    sessionStorage.setItem('activePath',res.data.data[0].children[0].route);
                  }else{
                    this.$message({ duration: 2000,showClose: true,message: '您暂无功能权限，请联系管理员！',type: 'error' });
                  }
                }
              })
            }else{
              this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
            }
          })
        }
      })
    },
    //重置密码
    getresetPassword(data){
      this.resetdialog=true;
    },
    submitresetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('此操作将重置该用户密码, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
            let parmas = this.resetform;
            resetPassword(parmas).then(res => {
              if(res.data.code==200){
                this.resetdialog=false;
                this.$message({ duration: 2000,showClose: true,message: '重置密码成功',type: 'success' });
                this.$refs[formName].resetFields();
              }else{
                this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
              }
            });
          }).catch(() => {
            this.$message({ duration: 2000,showClose: true,type: 'info',message: '已取消重置密码'});          
          });
        } else {
          return false;
        }
      });
    },
    resetresetForm(formName) {
      this.resetdialog=false;
      this.$refs[formName].resetFields();
    },
    //重置密码end
    getCode(){
      // console.log(this.resetform.phone)
      if(this.resetform.phone==''){
        this.$message({ duration: 2000,showClose: true,message: '请输入手机号',type: 'error' });
        return false;
      }
      if(this.resetform.phone){
        sendCode({phone:this.resetform.phone}).then(res => {
          if(res.data.code==200){
            this.$message({ duration: 2000,showClose: true,message: '验证码发送成功',type: 'success' });
          }else{
            this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
          }
        });
        // 因为下面用到了定时器，需要保存this指向
        let that = this
        that.waitTime--
        that.getCodeBtnDisable = true
        this.codeBtnWord = `${this.waitTime}s 后重新获取`
        let timer = setInterval(function(){
          if(that.waitTime>1){
            that.waitTime--
            that.codeBtnWord = `${that.waitTime}s 后重新获取`
          }else{
            clearInterval(timer)
            that.codeBtnWord = '获取验证码'
            that.getCodeBtnDisable = false
            that.waitTime = 61
          }
        },1000)
      }
    }
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}
  .head {
    width: 100%;
    height: 70px;
    background-color: rgba(25, 26, 35, 1);
    display: flex;
    align-items: center;
  }
    .head_img {
      width: 50px;
      height: 50px;
      margin-left: 37px;
    }
     .head_img img {
        width: 100%;
        height: 100%;
      }
    .head_img .title {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      margin-left: 30px;
    }
  
  .content {
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
  }
   .content .login_title{
      position: absolute;
      top: 24%;
      left: 10%;
      z-index: 3;
    }
   .content .bgColor{
      width:100%;
      height:100%;
      position: absolute;
      background: rgba(10,191,137,0.5);
      z-index: 1;
      top:0;
      left:0;
    }
   .content .form_data {
      position: absolute;
      bottom: 24%;
      right: 30px;
      width: 382px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px #cccccc;   
      padding: 62px 55px 62px 55px;
      border-radius:20px;
      z-index: 2;
    }
     .content h1 {
        font-size: 18px;
      }
     .content .el-form {
        margin-top: 40px;
      }
      .submit {
        width: 100%;
      }
     .content .forget {
        float: right;
      }
    .footer{
      position: absolute;
      bottom: 30px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
    
   .footer ul{
        display: flex;
      }
      .footer ul li{
          margin-left: 10px;
        }
     .footer p{
        margin-top: 20px;
        font-weight: 400;
        font-style: normal;
        color: #CCCCCC;
        line-height: 24px;
        text-align: center;
      }
</style>
<style>
.form_data .el-form-item{
  margin-bottom: 36px;
}
  .el-button.disabled-style {
    background-color: #EEEEEE;
    color: #CCCCCC;
    border-color:#EEEEEE;
  }
</style>
