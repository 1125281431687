
export const getuserInfo = (context, pro) => {
    context.commit('getuserInfo', pro);
};
export const clearUserInfo = (context, pro) => {
    context.commit('clearUserInfo', pro);
};
export const setToken = (context, pro) => {
    context.commit('setToken', pro);
};
export const clearToken = (context, pro) => {
    context.commit('clearToken', pro);
};
export const setcallStatus = (context, pro) => {
    context.commit('setcallStatus', pro);
};
export const clearcallStatus = (context, pro) => {
    context.commit('clearcallStatus', pro);
};
export const setcallLoadingStatus = (context, pro) => {
    context.commit('setcallLoadingStatus', pro);
};
export const clearcallLoadingStatus = (context, pro) => {
    context.commit('clearcallLoadingStatus', pro);
};
export const setcustomerCall = (context, pro) => {
    context.commit('setcustomerCall', pro);
};
export const clearcustomerCall = (context, pro) => {
    context.commit('clearcustomerCall', pro);
};