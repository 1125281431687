<template>
  <div class="home">
    <el-container>
        <el-header class="mantis-main-stage-header header_header__9bYHJ" style="height:52px;">
          <div style="display: flex; align-items: center;">
            <div class="header_logo__ahuhU">
              <!-- <img src="../assets/loginImages/logo_head.png"/> -->
              <span style="color:#fff;font-size:20px;">ERP系统</span>
            </div>
          </div>
          <div style="display: flex; align-items: center; flex: 1 1 0%;">
            
            <el-menu :collapse="isCollapse" collapse-transition router 
            :default-active="activePath" unique-opened class="el-menu-demo" mode="horizontal"
            background-color="#1e222d" text-color="#fff" active-text-color="#fff" style="margin-left:50px;">
              <el-submenu v-for="item in menulist" :key="item.id" :index="item.id + ''">
                        <template slot="title">
                          <span :class="['iconfont', item.icon]"></span>
                          <span>{{item.menu_name}}</span>
                        </template>
                        <el-menu-item-group>
                          <el-menu-item 
                            :route="{ path: subItem.route }"
                            :index="subItem.route"
                            v-for="subItem in item.children" 
                            :key="subItem.id"
                            @click="handleNavState(subItem.route)"
                          >
                            <i class="iconfont" :class="subItem.icon"></i>
                            <span>{{subItem.menu_name}}</span>
                          </el-menu-item>
                        </el-menu-item-group>
              </el-submenu>
            </el-menu>
            <div class="mantis-new-stage-header-container">
              <ul class="mantis-new-stage-header-ul">
                <!-- <li>
                  <span style="color:#fff;">最后登录IP：</span>
                  <el-tooltip class="item" effect="dark" :content="userInfo.login_at" placement="bottom">
                    <el-button type="text">{{userInfo.last_address}}</el-button>
                  </el-tooltip>
                </li> -->
                <li>
                  <el-dropdown divided="true" class="header-item" trigger="click">
                    <div class="head-right">
                      <!-- 用户名称 -->
                      <span class="username" style="color:#fff;">{{userInfo && userInfo.name ?userInfo.name:'游客' }}</span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <!-- 修改密码 -->
                      <el-dropdown-item>
                        <span class="logout" @click="changePassword">
                          修改密码
                        </span>
                      </el-dropdown-item>
                      <!-- 退出登录 -->
                      <el-dropdown-item divided>
                        <span class="logout" @click="handleLogout">
                          安全退出
                        </span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
                <li style="color: #657180;font-size:12px;">
                  <span>版本号：</span>0.0.1
                </li>
              </ul>
            </div>
          </div>
        </el-header>

      <el-container style="height: calc(100% - 52px);">
        <!-- <el-aside width="200px" style="background:#1e222d;">
          <el-menu :collapse="isCollapse" collapse-transition router 
          :default-active="activePath" unique-opened class="el-menu-vertical-demo" 
          background-color="#1e222d" text-color="#fff" active-text-color="#fff">
            <el-submenu v-for="item in menulist" :key="item.id" :index="item.id + ''">
                      <template slot="title">
                        <span :class="['iconfont', item.icon]"></span>
                        <span>{{item.menu_name}}</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item 
                          :route="{ path: subItem.route }"
                          :index="subItem.route"
                          v-for="subItem in item.children" 
                          :key="subItem.id"
                          @click="handleNavState(subItem.route)"
                        >
                          <i class="iconfont" :class="subItem.icon"></i>
                          <span>{{subItem.menu_name}}</span>
                        </el-menu-item>
                      </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside> -->
        <el-main>
          <div class="content">
            <div class="content_head">
              <div v-if="$route.name" class="title">
                <!-- {{$route.name}} -->
              </div>
              <el-tabs type="card" v-if="historys&&historys.length>0"
              :value="currentPath"
              @tab-click="tabClick"
              @tab-remove="tabRemove"
              closable class="content">
                <el-tab-pane v-for="item in historys" :key="item.path" :label="item.name" :name="item.path"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="content_info">
              <router-view></router-view>
            </div>
          </div>
        </el-main>
      </el-container>
      
    </el-container>

    <!-- 修改密码 -->
    <!-- <el-dialog title="重置密码" :visible.sync="changedialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="userInfo.updatePass==2?false:true">
      <el-form :model="changeform" :rules="changerules" ref="changeform">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="old_password">
          <el-input type="password" v-model="changeform.old_password" autocomplete="off" size="mini" prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
          <el-input type="password" v-model="changeform.password" placeholder="请输入八位密码要求字母大小写和数字" autocomplete="off" size="mini" prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="password_confirmation">
          <el-input type="password" v-model="changeform.password_confirmation" autocomplete="off" size="mini" prefix-icon="el-icon-lock" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="userInfo.updatePass!=2" @click="resetchangeForm('changeform')" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitchangeForm('changeform')" size="mini">确 定</el-button>
      </div>
    </el-dialog> -->
    <!-- 修改密码end -->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from "vuex";
import { updatePassword,loginOut} from '@/api/api.js'

export default {
  components: {
  },
  // 定义属性
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changeform.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      isCollapse:false,
      activePath: "",
      menulist: [],
      changedialog:false,//修改密码
      formLabelWidth:'120px',
      changeform:{
        id:'',
        token:'',
        name:'',
        password:'',
        password_confirmation:'',
      },
      changerules: {
        old_password: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        password_confirmation: [
          { required: true,validator: validatePass1, trigger: 'blur' }
        ],
      },//修改密码end
      adddialogFather:false,
      customerId:'',
      historys:[],
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.userInfo);
    if(this.userInfo&&this.userInfo.updatePass){
      if(this.userInfo.updatePass==2){
        this.changeform.name=this.userInfo.name;
        this.changeform.id=this.userInfo.id;
        this.changedialog=true;
      }
    }
    this.activePath = sessionStorage.getItem("activePath");
    this.menulist = this.rightList;
    console.log(this.menulist)

  },
  computed: {
    ...mapState(['rightList','userInfo','callStatus','callLoadingStatus','customerCall']),
    currentPath(){
      return this.$store.getters.currentPath;
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  // 监控data中的数据变化
  watch: {
    $route() {
      this.historys=this.$router.history.current.matched;
      console.log("router", this.$router.history);
      console.log(this.historys)
    },
  },
  // 方法集合
  methods: {
    tabClick({name}){
      this.$store.commit('menu/addHistoryPath',name);
      if(name!==this.$route.path){
        this.$router.push(name);
      }
    },
    tabRemove(path){
      this.$store.commit('history/removeHistory',path);
      this.$store.commit('history/removeHistoryPath',path);
    },
    addcustomer(){
      this.adddialogFather=true;
    },
    chooseData(){
      this.adddialogFather=false;
    },
    handleNavState(activePath) {
      this.activePath = activePath;
      sessionStorage.setItem("activePath", activePath);
    },
    handleLogout() {
      loginOut({}).then(res => {
        if(res.data.code==200){
          // console.log(res.data.data);
          sessionStorage.removeItem('qiyue_user')
          sessionStorage.removeItem('biToken')
          sessionStorage.removeItem('activePath')
          sessionStorage.removeItem('setRightList')
          sessionStorage.removeItem('searchformbox')
          this.$router.push('/login')
        }else{
          this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
        }
      });
    },
    //修改密码
    changePassword(){
      this.changeform.name=this.userInfo.name;
      this.changeform.id=this.userInfo.id;
      this.changedialog=true;
    },
    submitchangeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('此操作将修改该用户密码, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
            let parmas = this.changeform;
            parmas.token=sessionStorage.getItem('biToken')|| '';
            updatePassword(parmas).then(res => {
              if(res.data.code==200){
                this.changedialog=false;
                this.$message({ duration: 2000,showClose: true,message: '修改密码成功',type: 'success' });
                this.$refs[formName].resetFields();
                sessionStorage.removeItem('qiyue_user')
                sessionStorage.removeItem('biToken')
                sessionStorage.removeItem('activePath')
                sessionStorage.removeItem('setRightList')
                sessionStorage.removeItem('searchformbox')
                this.$router.push('/login')
              }else{
                this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
              }
            });
          }).catch(() => {
            this.$message({ duration: 2000,showClose: true,type: 'info',message: '已取消修改密码'});          
          });
        } else {
          return false;
        }
      });
    },
    resetchangeForm(formName) {
      this.changedialog=false;
      this.$refs[formName].resetFields();
    },
    //修改密码end
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
};
</script>

<style scoped>
  .home {
    width: 100%;
    height: 100%;
  }
  .el-container {
    width: 100%;
    height: 100%;
  }
  .el-aside .el-menu{
    border: 0px ;
  }
  .el-main{
    padding: 0px;
    background: rgb(242, 245, 248);
  }
  .content{
    width: 100%;
    height: 100%;
  }
  .content_head{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    position: relative;
  }
          
  .content_head .title{
    box-sizing: border-box;
    color: #606266;
    font-size: 14px;
    padding: 7px 30px;
    background-color: #f2f5f8;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .content_info{
    box-sizing: border-box;
    width: 100%;
    /* height: 580px; */
    height: calc(100% - 100px);
    padding: 20px;
  }
  .el-menu-item span{
    color:#A5A7AB;
  }
  .el-menu-item.is-active span{
    color:#fff;
  }
  .el-menu-item.is-active{
    background-color: #0ABF89 !important;
    color:#fff;
  }
  .el-submenu__title .el-submenu__icon-arrow {
    color:#fff;
  }
  .home .el-header {
    height: 52px;
    padding: 0;
    line-height: unset;
    background: #262f3e;
  }
  .header_header__9bYHJ {
    position: relative;
    display: flex;
    align-items: center;
    height: 47px;
    background-color: #2b3d48;
    z-index: 1000;
    width: 100%;
    padding: 0;
  }
  .header_header__9bYHJ .header_logo__ahuhU {
    margin-left: 20px;
  }
  .header_header__9bYHJ .header_logo__ahuhU img {
    width: 205px;
    height: 43px;
  }
  .mantis-main-stage-header .mantis-new-stage-header-container {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
  }
  .mantis-main-stage-header .mantis-new-stage-header-ul {
    margin-bottom: 0;
    padding-right:20px;
  }
  .mantis-main-stage-header .mantis-new-stage-header-ul li {
    display: inline-block;
    vertical-align: middle;
  }
  .mantis-main-stage-header .mantis-new-stage-header-ul li:not(:empty) {
    margin-right: 12px;
  }
</style>
<style>
  .mantis-new-stage-header-ul .el-input__inner{
    background-color: #444A54;
    border:1px solid #444A54;
    color: #fff;
  }
  .mantis-new-stage-header-ul .stateStyleup .el-input__inner{
    /* background-color: #262f3e;
    border:1px solid #262f3e; */
    color:#2bc58f;
  }
  .mantis-new-stage-header-ul .stateStyledown .el-input__inner{
    /* background-color: #262f3e;
    border:1px solid #262f3e; */
    color:#F56C6C;
  }
  .el-submenu__title i{
    color:#fff;
  }
  .mantis-main-stage-header .el-dropdown{
    color:rgb(193, 198, 200);
  }
  .el-menu-vertical-demo .el-menu-item-group__title{
    padding:0;
  }
  .mantis-main-stage-header .el-menu--horizontal>.el-submenu .el-submenu__title{
    height: 52px;
    line-height: 52px;
  }
</style>
