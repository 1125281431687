import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest ="https://api.erp.essesuit.com";//测试地址
// let resquest="https://api.erp.essesuit.com";//正式地址

let resquestpdd="https://chuanyunjian.p.essesuit.com";//拼多多测试地址
//拼多多订单管理
export function pddOrder(params){
    return http.post(`${resquestpdd}/api/pddOrder/index`,params)
}
//拼多多订单管理end
// post请求 登陆
export function requestLogin(params){
    return http.post(`${resquest}/api/user/login`,params)
}
// 退出登陆
export function loginOut(params){
    return http.post(`${resquest}/api/user/loginOut`,params)
}
// 获取登陆用户的所有权限
export function loginUserPermissions(params){
    return http.post(`${resquest}/api/user/loginUserPermissions`,params)
}
/*********************** 栏目管理 ****************************** */
// 栏目列表
export function getmenulist(params){
    return http.post(`${resquest}/api/permission/index`,params)
}
//新增栏目
export function permissioncreate(params){
    return http.post(`${resquest}/api/permission/create`,params)
}
//修改栏目
export function permissionedit(params){
    return http.post(`${resquest}/api/permission/edit`,params)
}
//超级密码
export function getSuperPass(params){
    return http.post(`${resquest}/api/user/getSuperPass`,params)
}

/*********************** 部门管理 ****************************** */
//部门列表
export function departmentList(params){
    return http.post(`${resquest}/api/department/index`,params)
}
//新增部门
export function departmentcreate(params){
    return http.post(`${resquest}/api/department/create`,params)
}
//修改部门
export function departmentedit(params){
    return http.post(`${resquest}/api/department/edit`,params)
}
/*********************** 角色管理 ****************************** */
// 功能权限
export function permissions(params){
    return http.post(`${resquest}/api/role/permissions`,params)
}
// 角色列表
export function roleList(params){
    return http.post(`${resquest}/api/role/index`,params)
}
// 新增角色
export function rolecreate(params){
    return http.post(`${resquest}/api/role/create`,params)
}
// 修改角色
export function roleedit(params){
    return http.post(`${resquest}/api/role/edit`,params)
}
/*********************** 用户管理 ****************************** */
// 用户列表
export function userList(params){
    return http.post(`${resquest}/api/user/index`,params)
}
// 用户列表无分页
export function userListNoPage(params){
    return http.post(`${resquest}/api/user/listNoPage`,params)
}
// 新建用户
export function usercreate(params){
    return http.post(`${resquest}/api/user/create`,params)
}
// 修改用户
export function useredit(params){
    return http.post(`${resquest}/api/user/edit`,params)
}
// 添加数据权限
export function dataAuth(params){
    return http.post(`${resquest}/api/user/dataAuth`,params)
}
// 获取用户数据权限
export function dataAuthDel(params){
    return http.post(`${resquest}/api/user/getDataAuth`,params)
}
// 企业微信列表
export function weComList(params){
    return http.post(`${resquest}/api/user/weComList`,params)
}
// 企业微信授权
export function bindWeCom(params){
    return http.post(`${resquest}/api/user/bindWeCom`,params)
}
// 企业微信解除授权
export function removeBind(params){
    return http.post(`${resquest}/api/user/removeBind`,params)
}
// 同步天润
export function synTrUser(params){
    return http.post(`${resquest}/api/user/synTrUser`,params)
}
// 用户状态
export function userstatus(params){
    return http.post(`${resquest}/api/user/status`,params)
}
// 客户关系转移
export function changeBond(params){
    return http.post(`${resquest}/api/user/changeBond`,params)
}
// 重置密码
export function resetPassword(params){
    return http.post(`${resquest}/api/user/resetPassword`,params)
}
// 修改密码
export function updatePassword(params){
    return http.post(`${resquest}/api/user/updatePassword`,params)
}
// 验证码短信
export function sendCode(params){
    return http.post(`${resquest}/api/sms/sendCode`,params)
}
/*********************** 企业管理 ****************************** */
// 企业列表
export function companyList(params){
    return http.post(`${resquest}/api/company/index`,params)
}
// 企业增加
export function companyCreate(params){
    return http.post(`${resquest}/api/company/create`,params)
}
// 企业修改
export function companyUpdate(params){
    return http.post(`${resquest}/api/company/update`,params)
}
// 企业状态修改
export function companyStatus(params){
    return http.post(`${resquest}/api/company/delete`,params)
}
/*********************** 系统配置 ****************************** */
// 获取公海配置信息
export function SeaConfigData(params){
    return http.post(`${resquest}/api/config/getSeaConfig`,params)
}
// 修改公海配置
export function SeaConfigEdit(params){
    return http.post(`${resquest}/api/config/seaConfig`,params)
}

/*********************** 店铺管理 ****************************** */
// 店铺列表
export function shopList(params){
    return http.post(`${resquest}/api/shop/shopList`,params)
}
//店铺授权地址
export function shopTypeList(params){
    return http.post(`${resquest}/api/shop/shopIndex`,params)
}
//新增店铺
export function shopAdd(params){
    return http.post(`${resquest}/api/shop/shopAdd`,params)
}
//修改店铺
export function shopSet(params){
    return http.post(`${resquest}/api/shop/shopSet`,params)
}
//店铺状态修改
export function shopStatusSet(params){
    return http.post(`${resquest}/api/shop/shopStatusSet`,params)
}
//店铺同步订单修改
export function shopOrderAuth(params){
    return http.post(`${resquest}/api/shop/shopOrderAuth`,params)
}
/*********************** 仓库管理 ****************************** */
// 仓库列表
export function warehouseList(params){
    return http.post(`${resquest}/api/goodsDepository/index`,params)
}
// 仓库添加
export function warehouseAdd(params){
    return http.post(`${resquest}/api/goodsDepository/create`,params)
}
// 仓库修改
export function warehouseEdit(params){
    return http.post(`${resquest}/api/goodsDepository/edit`,params)
}
// 仓库修改状态
export function warehouseStatus(params){
    return http.post(`${resquest}/api/goodsDepository/status`,params)
}

/*********************** 商品管理 ****************************** */
/*********************** 商品分类管理 ****************************** */
// 商品分类列表
export function goodsCategoryList(params){
    return http.post(`${resquest}/api/goodsCategory/index`,params)
}
// 商品分类添加
export function goodsCategoryAdd(params){
    return http.post(`${resquest}/api/goodsCategory/create`,params)
}
// 商品分类修改
export function goodsCategoryEdit(params){
    return http.post(`${resquest}/api/goodsCategory/edit`,params)
}
// 商品分类修改状态
export function goodsCategoryStatus(params){
    return http.post(`${resquest}/api/goodsCategory/status`,params)
}
/*********************** 商品品牌管理 ****************************** */
// 商品品牌列表
export function goodsBrandList(params){
    return http.post(`${resquest}/api/goodsBrand/index`,params)
}
// 商品品牌添加
export function goodsBrandAdd(params){
    return http.post(`${resquest}/api/goodsBrand/create`,params)
}
// 商品品牌修改
export function goodsBrandEdit(params){
    return http.post(`${resquest}/api/goodsBrand/edit`,params)
}
// 商品品牌修改状态
export function goodsBrandStatus(params){
    return http.post(`${resquest}/api/goodsBrand/status`,params)
}
/*********************** 商品数据管理 ****************************** */
// 商品列表
export function goodsLocalList(params){
    return http.post(`${resquest}/api/goodsLocal/index`,params)
}
// 商品添加
export function goodsLocalAdd(params){
    return http.post(`${resquest}/api/goodsLocal/create`,params)
}
// 商品修改
export function goodsLocalEdit(params){
    return http.post(`${resquest}/api/goodsLocal/edit`,params)
}
// 商品修改状态
export function goodsLocalStatus(params){
    return http.post(`${resquest}/api/goodsLocal/status`,params)
}
/*********************** 组合商品 ****************************** */
// 商品列表
export function goodsLocalGroupList(params){
    return http.post(`${resquest}/api/goodsLocalGroup/index`,params)
}
// 商品添加
export function goodsLocalGroupAdd(params){
    return http.post(`${resquest}/api/goodsLocalGroup/create`,params)
}
// 商品修改
export function goodsLocalGrouplEdit(params){
    return http.post(`${resquest}/api/goodsLocalGroup/edit`,params)
}
// 商品修改状态
export function goodsLocalGroupStatus(params){
    return http.post(`${resquest}/api/goodsLocalGroup/status`,params)
}
/***********************仓库入库 ****************************** */
//仓库入库记录
export function putStorageList(params){
    return http.post(`${resquest}/api/goodsDepository/inputList`,params)
}
//仓库入库新增
export function putStorageAdd(params){
    return http.post(`${resquest}/api/goodsDepository/inputCreate`,params)
}
/***********************仓库出库 ****************************** */
//仓库出库记录
export function outStorageList(params){
    return http.post(`${resquest}/api/goodsDepository/outputList`,params)
}
//仓库出库新增
export function outStorageAdd(params){
    return http.post(`${resquest}/api/goodsDepository/outputCreate`,params)
}

/***********************订单审核 ****************************** */
//订单审核列表
export function orderCheckList(params){
    return http.post(`${resquest}/api/order/orderCheckList`,params)
}
//订单审核列表详情
export function orderReviewDetail(params){
    return http.post(`${resquest}/api/order/orderReviewDetail`,params)
}
//组合商品详情
export function orderGoodsStock(params){
    return http.post(`${resquest}/api/order/orderGoodsStock`,params)
}
// 订单备注修改
export function orderRemarkSet(params){
    return http.post(`${resquest}/api/order/orderRemarkSet`,params)
}
// 订单收货地址修改
export function orderAddressSet(params){
    return http.post(`${resquest}/api/order/orderAddressSet`,params)
}
// 手动添加订单
export function addOrderReview(params){
    return http.post(`${resquest}/api/order/addOrder`,params)
}


/*********************** 公共接口 ****************************** */
export const uploadFiles =resquest + '/api/upload/oss'; //文件上传



