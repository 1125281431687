import axios from 'axios';
import _this from '../main.js';
import store from '../vuex/store';
let loading;

function startLoading() {
    loading && loading.close();
    loading = _this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.2)',
        target: document.querySelector('#app'),
    });
}

function endLoading() {
    if(loading)loading.close();
}
//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 5 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(
    function(config) {
        if (config && config.url.indexOf('/api/report/synReport') == -1){
            startLoading();
        }
        if (config && config.url.indexOf('/login') == -1 ||(config.url.indexOf('/loginOut') != -1||config.url.indexOf('/loginUserPermissions') != -1) ) {
            // console.log(store.state.userInfo)
            let token = store.state.userInfo && store.state.userInfo.token ? store.state.userInfo.token : sessionStorage.getItem('token');
            if (token) {
                config.headers.token = token; //将token放到请求头发送给服务器
                //这里经常搭配token使用，将token值配置到tokenkey中，将tokenkey放在请求头中 // config.headers['token'] = Token;
            }
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// 3.响应拦截器
service.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            if (response.data.code == 10002||response.data.code == 10003||response.data.code == 10004) {
                // endLoading();
                // return Promise.resolve(response);
                endLoading();
                // _this.$message.error('登录已过期，请重新登录')
                store.dispatch('setToken',null)
                sessionStorage.removeItem('biToken')
                store.dispatch('getuserInfo',null)
                sessionStorage.removeItem('qiyue_user')
                sessionStorage.removeItem('searchformbox')
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    _this.$router.push({
                        path: '/login',
                    });
                }, 200);
                return;
            }

            if (response.data.code == 400001||response.data.code == 400002||response.data.code == 400003||response.data.code == 400004) {
                endLoading();
                // _this.$message.error('登录已过期，请重新登录')
                store.dispatch('setToken',null)
                sessionStorage.removeItem('biToken')
                store.dispatch('getuserInfo',null)
                sessionStorage.removeItem('qiyue_user')
                sessionStorage.removeItem('searchformbox')
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    _this.$router.push({
                        path: '/login',
                    });
                }, 200);
                return;
            }
            // if (response.data.code == 10003) {
            //     _this.$message.error('当前页面没有分配权限，请联系管理员！');
            //     _this.$router.replace({ path: '/noaccess' });
            // }
            if (response.config.url.indexOf('/mailnolook') == -1) {
                endLoading();
            }
            return Promise.resolve(response);
        } else {
            endLoading();
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    (error) => {
        console.log(error)
        if (error.response && error.response.status) {
            endLoading();
            switch (error.response.status) {
                case 500:
                    _this.$confirm('服务器异常，请稍候再试', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton: false,
                        type: 'warning',
                        center: true,
                    });
                    break;
                    // 10001 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    _this.$message.error('登录已过期，请重新登录');
                    sessionStorage.removeItem('user');
                    store.commit('userInfo', null);
                    setTimeout(() => {
                        _this.$router.push({
                            path: '/login',
                        });
                    }, 1000);
                    break;
                    // 404请求不存在
                case 404:
                    _this.$message.error({
                        showClose: true,
                        message: '网络请求不存在',
                        type: 'error',
                    });
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    _this.$message.error(error.response.data.msg);
            }
            return Promise.reject(error.response);
        } else {
            endLoading();
            _this.$message.error({
                showClose: true,
                message: '网络异常，请检查网络，稍候再试',
                type: 'error',
            });
        }
    }
);
//4.导入文件
export default service