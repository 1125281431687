import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex/store.js';
import Vuex from 'vuex';
import ElementUI from 'element-ui';
// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css';
// import './common/font/font.css';
import './common/font/fontBold.css';
import './assets/font/iconfont.css'; //阿里云字体图标

Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.use(Vuex);
Vue.use(ElementUI);

// new Vue({
//   render: h => h(App),
//   router,
//   store,
// }).$mount('#app')

let $vue = new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
export default $vue
